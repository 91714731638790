import React from "react"
import { graphql, Link } from "gatsby"
import { Breadcrumb } from "gatsby-plugin-breadcrumb"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Truncate from "../components/truncate";
import YoutubeVideo from "../components/youtube-video";
import Pagination from "../components/pagination";

const VideoList = ({ pageContext, data, location }) => {
  const {
    breadcrumb: { crumbs },
    currentPage,
    numPages
  } = pageContext

  return (
    <Layout>
      <SEO
          title="Nos invités en vidéo"
          description="Retrouvez la liste des invités de la radio Fugi FM en vidéo."
      />
      <div id="videos-list" className="container list-page">
        <section className="section page-header">
          <Breadcrumb
            crumbs={crumbs}
            crumbSeparator=" > "
            crumbLabel="Nos invités en vidéo"
          />

          <h1 className="title is-1">Nos invités en vidéo</h1>
        </section>

        <section className="section page-content">
          <div id="videos-list" className="columns is-multiline">
            {data.allNodeVideo.nodes.map((node) => (
              <div className="column is-6" key={node.id}>
                <Link to={node.path.alias}>
                  <YoutubeVideo url={node.field_video.uri} thumbnail={true} />
                </Link>

                <h3 className="title is-3">
                  <Link to={node.path.alias}>{node.title}</Link>
                </h3>

                {node.body && (
                  <div className="content">
                    <Truncate limit={200} className="body" html={(
                      node.body.summary.length > 0
                        ? node.body.summary
                        : node.body.value
                      )}
                    />
                  </div>
                )}
              </div>
            ))}
          </div>

          <Pagination
            path={location.pathname}
            currentPage={currentPage}
            numPages={numPages}
          />
        </section>
      </div>
    </Layout>
  )
}

export default VideoList

export const query = graphql`
  query videosListQuery($skip: Int!, $limit: Int!) {
    allNodeVideo(
      filter: {status: {eq: true}}, 
      sort: {fields: created, order: DESC},
      limit: $limit, 
      skip: $skip
    ) {
      nodes {
        id
        title
        body {
          summary,
          value
        }
        field_video {
          uri
        }
        path {
          alias
        }
      }
    }
  }`
